<template>
  <b-container fluid>
    <b-container>

      <h1>Wyślij wiadomość</h1>

      <b-form-group id="subjects" label="Temat * " label-for="subjects">
        <b-form-select v-model="contact.subject"
                       @input="$v.contact.subject.$touch()"
                       @change="resetApiValidation('subject')"
                       :state="(!$v.contact.subject.$error && validation.subject == null) && null"
                       :options="subjects" class="mt-3"
                       aria-describedby="subject-feedback"></b-form-select>
        <b-form-invalid-feedback id="subject-feedback">
          <div class="error" v-if="!$v.contact.subject.required">Pole wymagane</div>
          {{validation.subject}}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group id="subjects" label="Numer karty*" label-for="subjects">
        <b-input v-model="contact.nr"
                 @input="$v.contact.nr.$touch()"
                 @change="resetApiValidation('nr')"
                 :state="(!$v.contact.nr.$error && validation.nr == null) && null"
                 aria-describedby="cardNumber-feedback"></b-input>
        <b-form-invalid-feedback id="cardNumber-feedback">
          <div class="error" v-if="!$v.contact.nr.required">Pole wymagane</div>
          {{validation.nr}}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group id="first-name" label="Imię i nazwisko" label-for="first_last_name">
        <b-form-input v-model="contact.first_last_name"
                      @input="$v.contact.first_last_name.$touch()"
                      @change="resetApiValidation('first_last_name')"
                      :state="(!$v.contact.first_last_name.$error && validation.first_last_name == null) && null"
                      aria-describedby="first_name-feedback"></b-form-input>
        <b-form-invalid-feedback id="first_name-feedback">
          <div class="error" v-if="!$v.contact.first_last_name.required">Pole wymagane</div>
          {{validation.first_last_name}}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group id="email" label="Twój email" label-for="email">
        <b-form-input v-model="contact.email"
                      @input="$v.contact.email.$touch()"
                      @change="resetApiValidation('email')"
                      :state="(!$v.contact.email.$error && validation.email == null) && null"
                      aria-describedby="email-feedback"></b-form-input>
        <b-form-invalid-feedback id="email-feedback">
          <div class="error" v-if="!$v.contact.email.required">Pole wymagane</div>
          <div class="error" v-if="!$v.contact.email.email">Podano niepoprawny e-mail</div>
          {{validation.email}}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group id="email" label="Numer telefonu (opcjonalnie)" label-for="email">
        <b-form-input v-model="contact.phone"
                      @input="$v.contact.phone.$touch()"
                      @change="resetApiValidation('email')"
                      :state="(!$v.contact.phone.$error && validation.phone == null) && null"
                      aria-describedby="email-feedback"></b-form-input>
        <b-form-invalid-feedback id="email-feedback">
          <div class="error" v-if="!$v.contact.phone.minLength">Nieprawidłowy numer tel</div>
          {{validation.phone}}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group id="message" label="Treśc wiadomości" label-for="message">
        <b-textarea class="messageArea" v-model="contact.message"
                    @input="$v.contact.message.$touch()"
                    @change="resetApiValidation('message')"
                    :state="(!$v.contact.message.$error && validation.message == null) && null"
                    aria-describedby="message-feedback"></b-textarea>
        <b-form-invalid-feedback id="message-feedback">
          <div class="error" v-if="!$v.contact.message.required">Pole wymagane</div>
          {{validation.message}}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="RODO - Zgoda " label-for="tos">
        <b-form-checkbox switch
                         v-model="contact.tos"
                         @input="$v.contact.tos.$touch()"
                         @change="resetApiValidation('tos')"
                         :state="(!$v.contact.tos.$error) && null"
                         value="1"
                         unchecked-value="0"
                         aria-describedby="tos-feedback"
        >

        </b-form-checkbox>
        <div class="error" v-if="$v.contact.tos.$error">Proszę zaznaczyć zgodę</div>
      </b-form-group>

      <b-button variant="primary" @click="sendMessage" class="float-right" :disabled="loading || sended"
                style="min-width:300px">
        <b-spinner type="grow" label="Spinning" small v-if="loading"></b-spinner>
        <span v-if="!loading && !sended">Wyślij wiadomość</span>
        <span v-else-if="loading">Trwa wysyłanie wiadomości...</span>
        <span v-else>Wiadomość została wysłana</span>
      </b-button>
    </b-container>
  </b-container>

</template>

<script>
// TODO: Można zrobić sprawdzanie nr karty w backu i jeśli nr poprawny robić okejke na inpucie z nr karty (podobnie jak ze sprawdzaniem tokena)
import {
  required, minLength, minValue, email,
} from 'vuelidate/lib/validators';

import PagesService from '../_services/pages.service';
import NotifyService from '../_services/notify';

const notify = new NotifyService();
const pagesService = new PagesService();

export default {
  name: 'contact',
  data() {
    return {
      contact: {
        nr: '',
        email: '',
        phone: '',
        first_last_name: '',
        subject: 'Problemy z rejestracją',
        message: '',
        tos: 0,
      },
      validation: {
        subject: null,
        email: null,
        first_last_name: null,
        nr: null,
        message: null,
        tos: null,
      },
      subjects: ['Problemy z rejestracją', 'Zablokowane konto', 'Punkty w programie', 'Reklamacje', 'Inne'],
      loading: false,
      sended: false,
    };
  },
  methods: {
    resetApiValidation(key) {
      this.validation[key] = null;
      return true;
    },
    sendMessage() {
      this.$v.contact.$touch();
      if (this.$v.contact.$anyError) {
        return;
      }
      this.loading = true;
      this.send();
    },
    send() {
      return pagesService.sendContactMessage(this.contact)
        .then((response) => {
          if (typeof response.errors !== 'undefined') {
            Object.keys(response.errors)
              .forEach((key) => {
                this.validation[key] = response.errors[key];
              });
            return;
          }
          if (response.success === false) {
            notify.error('Problem', response.message);
          }
          if (response.success === true) {
            notify.success('OK', response.message);
            this.sended = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  validations: {
    contact: {
      subject: { required },
      first_last_name: { required },
      nr: { required },
      phone: { minLength: minLength(9, 13) },
      email: {
        required,
        email,
      },
      message: { required },
      tos: {
        required,
        minValue: minValue(1),
      },
    },
  },
};
</script>
<style scoped>
  .messageArea {
    min-height: 250px;
  }
</style>
